<template>
  <div>
    <div class="main-title">
      <h1 class="main-title-info">Income Calculator for Creators</h1>
    </div>

    <div class="main-content">
      <div class="pricing">
        <h1>Massive revenue from events for communities and fans.</h1>
        <p>Event Revenue:</p>
        <div class="pricing-item-price">
          <!-- PESO SIGN -->
          <span class="pricing-item-price-currency"> </span>
          <span class="pricing-item-price-amount">{{
            formatMoney(total)
          }}</span>
        </div>

        <h2>
          Estimate number of <span style="color: #25a4e1">attendees:</span>
        </h2>
        <div class="pricing-slider center-content">
          <label class="form-slider">
            <input ref="slider1" v-model="priceInputValue" type="range" />
            <div class="value">
              <p>50</p>
              <p>100</p>
              <p>250</p>
              <p>500</p>
              <p>1,000</p>
              <p>5,000</p>
            </div>
          </label>
          <div ref="sliderValue" class="pricing-slider-value">
            {{ getPricingData(priceInput).text }}
          </div>
        </div>

        <h2 style="margin-top: 7rem">
          How much do you want to <span style="color: #25a4e1">charge?</span>
        </h2>
        <div class="pricing-slider center-content">
          <label class="form-slider">
            <input ref="slider2" v-model="priceChargeValue" type="range" />
            <div class="value1">
              <p>₱199</p>
              <p>₱499</p>
              <p>₱999</p>
              <p>₱2,500</p>
              <p>₱5,000</p>
              <p>₱7,500</p>
            </div>
          </label>
          <div ref="sliderValue" class="pricing-slider-value">
            {{ getChargeData(priceCharge).text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import accounting from 'accounting';

export default {
  name: 'Pricing',
  data() {
    return {
      priceInputValue: '2',
      priceInput: [
        { id: 0, text: '', amount: 50 },
        { id: 1, text: '', amount: 100 },
        { id: 2, text: '', amount: 250 },
        { id: 3, text: '', amount: 500 },
        { id: 4, text: '', amount: 1000 },
        { id: 5, text: '', amount: 5000 },
      ],
      priceChargeValue: '2',
      priceCharge: [
        { id: 0, text: '', amount: 199 },
        { id: 1, text: '', amount: 499 },
        { id: 2, text: '', amount: 999 },
        { id: 3, text: '', amount: 2500 },
        { id: 4, text: '', amount: 5000 },
        { id: 5, text: '', amount: 7500 },
      ],
    };
  },

  computed: {
    total: function () {
      return (
        this.priceInput[this.priceInputValue].amount *
        this.priceCharge[this.priceChargeValue].amount
      );
    },
  },

  mounted() {
    this.$refs.slider1.setAttribute('min', 0);
    this.$refs.slider1.setAttribute(
      'max',
      Object.keys(this.priceInput).length - 1
    );

    this.$refs.slider2.setAttribute('min', 0);
    this.$refs.slider2.setAttribute(
      'max',
      Object.keys(this.priceCharge).length - 1
    );
  },

  methods: {
    getPricingData(obj, pos) {
      console.log(obj, pos, 'getPricingData');
      return pos !== undefined
        ? obj[this.priceInputValue][pos]
        : obj[this.priceInputValue];
    },
    getChargeData(obj, pos) {
      console.log(obj, pos, 'getChargeData');
      return pos !== undefined
        ? obj[this.priceChargeValue][pos]
        : obj[this.priceChargeValue];
    },
    formatMoney(amount) {
      return accounting.formatMoney(amount, '₱');
    },
  },
};
</script>

<style scoped>
*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
}

body {
  font-size: 1rem;
  margin: 0;
  padding: 48px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.charge-numbers {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

body,
button,
input,
select,
textarea {
  font-family: 'Heebo', sans-serif;
  font-weight: 400;
}

.main-title {
  display: none;
  width: 100%;
  height: inherit;
  background-image: url('/img/header-wallpaper-creators.png');
}

.main-title-info {
  font-size: 2.5rem;
  padding-top: 7rem;
  padding-bottom: 7rem;
  margin: auto;
  width: 50rem;
  text-align: center;
  color: white;
}

.main-content {
  width: 100%;
  margin: auto;
  display: grid;
  padding-top: 3rem;
  padding-bottom: 3rem;
  grid-template-columns: 1fr;
  align-items: center;
}

.pricing {
  width: 50rem;
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pricing h1 {
  width: 70%;
  margin: auto;
  font-size: 1.5rem;
  font-weight: 800;
  align-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 3rem;
}

.pricing p {
  font-size: 1rem;
  font-weight: 700;
  align-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 1rem;
}

.pricing h2 {
  width: 70%;
  margin: auto;
  display: block;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 16px;
  font-family: 'Montserrat', sans-serif;
}

.form-slider {
  width: 100%;
}

.pricing-slider {
  width: 100%;
  margin-bottom: 48px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.button {
  display: flex;
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  padding: 12px 29px;
  text-decoration: none !important;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #25a4e1;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  cursor: pointer;
  justify-content: center;
  text-align: center;
}

.button:hover {
  background-color: #1a89bd;
  transition: 0.4s ease-in-out;
}

.value {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: rgb(167, 167, 167);
}

.value p {
  font-size: 0.7rem;
  position: absolute;
}

.value p:nth-child(1) {
  margin-left: 0.2rem;
}

.value p:nth-child(2) {
  margin-left: 9.7rem;
}

.value p:nth-child(3) {
  margin-left: 19.5rem;
}

.value p:nth-child(4) {
  margin-left: 29.2rem;
}

.value p:nth-child(5) {
  margin-left: 38.7rem;
}

.value p:nth-child(6) {
  margin-left: 48.5rem;
}

.value1 {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: rgb(167, 167, 167);
}

.value1 p {
  font-size: 0.7rem;
  position: absolute;
}

.value1 p:nth-child(1) {
  margin-left: -0.2rem;
}

.value1 p:nth-child(2) {
  margin-left: 9.3rem;
}

.value1 p:nth-child(3) {
  margin-left: 19rem;
}

.value1 p:nth-child(4) {
  margin-left: 28.8rem;
}

.value1 p:nth-child(5) {
  margin-left: 38.2rem;
}

.value1 p:nth-child(6) {
  margin-left: 48rem;
}

input[type='range'] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: rgb(167, 167, 167);
  border-radius: 3px;
  height: 15px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  outline: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

input[type='range']::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  background-image: linear-gradient(to top, #25a4e1, #25a4e1);
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  height: 36px;
  width: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

input[type='range']::-moz-focus-outer {
  border: 0;
}

.pricing-slider input {
  width: 100%;
}

.pricing-slider .pricing-slider-value {
  position: absolute;
  font-size: 1rem;
  line-height: 22px;
  font-weight: 500;
  color: #909cb5;
  margin-top: 8px;
  --thumb-size: 36px;
}
.pricing-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: -12px;
  margin-left: -12px;
  margin-top: -12px;
}

.pricing-item {
  flex-basis: 280px;
  max-width: 280px;
  box-sizing: content-box;
  padding: 12px;
}

.pricing-item-inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  padding: 24px;
  box-shadow: 0 8px 16px rgba(46, 52, 88, 0.16);
}

.pricing-item-title {
  font-weight: 500;
}

.pricing-item-price {
  width: 70%;
  margin: auto;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  align-items: baseline;
  font-size: 2rem;
  font-weight: 700;
  border: 3px solid #25a4e1;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.pricing-item-price-amount {
  font-size: 2rem;
  line-height: 48px;
  font-weight: 700;
  color: #191e2a;
}
.pricing-item-features-list {
  list-style: none;
  padding: 0;
}
.pricing-item-features-list li {
  margin-bottom: 0;
  padding: 14px 0;
  position: relative;
  display: flex;
  align-items: center;
}
.pricing-item-features-list li::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: #e9ecf8;
}
.pricing-item-features-list li::after {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M5%2011h14v2H5z%22%20fill%3D%22%239298B8%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  -webkit-box-ordinal-group: 0;
  order: -1;
}
.pricing-item-features-list li.is-checked::after {
  background-size: 1rem;
  background-position: 0% 43%;
  background-image: url('../assets/images/check-list-saas.svg');
}

@media only screen and (max-width: 1050px) {
  .main-title h1 {
    width: 40rem;
  }
}

@media only screen and (max-width: 800px) {
  .pricing {
    width: 30rem;
  }

  .main-title h1 {
    font-size: 1.5rem;
    width: 30rem;
  }

  .pricing-item-price {
    width: 100%;
    padding: 0;
  }

  .value p:nth-child(2) {
    margin-left: 5.8rem;
  }

  .value p:nth-child(3) {
    margin-left: 11.5rem;
  }

  .value p:nth-child(4) {
    margin-left: 17.2rem;
  }

  .value p:nth-child(5) {
    margin-left: 22.6rem;
  }

  .value p:nth-child(6) {
    margin-left: 28rem;
  }

  .value1 p:nth-child(2) {
    margin-left: 5.3rem;
  }

  .value1 p:nth-child(3) {
    margin-left: 11rem;
  }

  .value1 p:nth-child(4) {
    margin-left: 16.8rem;
  }

  .value1 p:nth-child(5) {
    margin-left: 22.3rem;
  }

  .value1 p:nth-child(6) {
    margin-left: 28rem;
  }
}

@media only screen and (max-width: 650px) {
  .main-title {
    width: 100%;
  }

  .main-title h1 {
    width: 100%;
  }

  .pricing {
    width: 20rem;
  }

  .pricing h1 {
    width: 100%;
  }

  .pricing h2 {
    font-size: 1rem;
  }

  .pricing-item-price-amount {
    width: 100%;
  }

  .pricing-item-title,
  .pricing-item-price-amount,
  .pricing-item-price {
    font-size: 1rem;
  }

  .value p:nth-child(2) {
    margin-left: 3.8rem;
  }

  .value p:nth-child(3) {
    margin-left: 7.5rem;
  }

  .value p:nth-child(4) {
    margin-left: 11.2rem;
  }

  .value p:nth-child(5) {
    margin-left: 14.7rem;
  }

  .value p:nth-child(6) {
    margin-left: 18.3rem;
  }

  .value1 p:nth-child(2) {
    margin-left: 3.4rem;
  }

  .value1 p:nth-child(3) {
    margin-left: 7rem;
  }

  .value1 p:nth-child(4) {
    margin-left: 10.8rem;
  }

  .value1 p:nth-child(5) {
    margin-left: 14.3rem;
  }

  .value1 p:nth-child(6) {
    margin-left: 18rem;
  }
}
</style>
